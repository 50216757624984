import { serviceConfig } from "./config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const makeRequest = async (service, endpoint, method, params, data, header = []) => {
  const axiosConfig = serviceConfig[service];
  if (!axiosConfig) {
    throw new Error(`Invalid service: ${service}`);
  }
  const serviceInstance = axios.create(axiosConfig);
  serviceInstance.interceptors.request.use((request) => {
    // Common header function for the all the api routes
    // const storedToken = window.localStorage.getItem("accessToken");

    // if (storedToken) {
    //   request.headers.Authorization = `Bearer ${storedToken}`;
    // }

    if (header?.length > 0) {
      header.forEach((h) => {
        request.headers[h.key] = h.value;
      });
    }

    return request;
  });
  const getGenericErrorMessage = (e) => {
    const text = e.response?.data?.errors
      ? Object.values(e.response?.data?.errors)[0]
      : e.response?.data?.message || "Something went wrong!";
    toast.error(text);
  };

  serviceInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 402) {
        toast.error(error?.response?.data?.message || "Unauthorized Access");
        window.localStorage.removeItem("accessToken");
        Router.push("/login");

        return error?.response;
      }
      if (error?.response?.status === 500) {
        toast.error("Internal Server Error");

        return error?.response;
      } else {
        if (error?.response?.status !== 422) {
          // getGenericErrorMessage(error);
        }
      }
      throw error;
    }
  );

  const response = await serviceInstance.request({
    method,
    url: endpoint,
    params,
    data,
  });

  return response;
};

export { makeRequest };
