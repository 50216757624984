import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { backendBaseUrl, freeBackendBaseUrl } from '../../api/config';
import { parseQueryParams } from '../../utils/utilityFunction';
import { fetchPolicyDetails } from './userSlice';
import { ACCESS_TYPE, getIp } from '../../constant/constant';
import {
  cleverTapEventPush,
  createCleverTapProfile
} from '../../utils/cleverTap';
import {
  CLEVER_TAP_EVENTS,
  defaultCleverTapValue
} from '../../config/cleverTapEvents';

const sendOtpBaseUrl = `${backendBaseUrl}/login/logintoken.php?`;

const verifyOtpBaseUrl = `${backendBaseUrl}/login/verifyotp.php?`;

const verifyOtpSignupBaseUrl = `${backendBaseUrl}/login/verifyotp.php?`;

const newUserGetTokenBaseUrl = `${backendBaseUrl}/login/verifyotp.php?`;

// const tokenUrl = "https://iomod-lms.herovired.com/login/otptoken.php?";

const programUrl = `${backendBaseUrl}/webservice/rest/server.php?`;

const passwordLoginBaseUrl = `${backendBaseUrl}/login/token.php?`;

const createUserBaseUrl = `${backendBaseUrl}/webservice/rest/server.php?`;

const verifyTokenBaseUrl = `${backendBaseUrl}/local/signin/verifytoken.php?`;

const verifyTokenBaseFreeUrl = `${freeBackendBaseUrl}/local/signin/freeverifytoken.php?`;

export const verifyToken = createAsyncThunk(
  'auth/verifyToken',
  async (values, thunkAPI) => {
    const location = window?.location?.pathname;
    try {
      let paramValue = parseQueryParams();
      if (paramValue.logout === 'true') {
        removeQueryParams('logout');
        thunkAPI.dispatch(logout());
        // return;
      }
      let verifyTokenParams = values?.withoutUserData
        ? {
            token: values?.token
          }
        : {
            token: values.token,
            userid: values.userData.userid
          };

      // console.log('verify token params', verifyTokenParams);

      let verifyTokenUrl =
        (values?.withoutUserData
          ? verifyTokenBaseFreeUrl
          : verifyTokenBaseUrl) + new URLSearchParams(verifyTokenParams);

      // console.log('verify token url', verifyTokenUrl);

      const response = await fetch(verifyTokenUrl);

      // console.log('verify token response', response);

      if (response.status == '200') {
        const data = await response.json();

        // console.log('verify token data', data);
        if (!data?.status && values?.withoutUserData) {
          window.location.replace('https://herovired.com/');
        }

        // if (values?.withoutUserData && res)

        if (data.status == true) {
          let check = JSON.parse(data?.user);
          if (data?.accesstype === 'Free' && location.includes('/free')) {
            localStorage.setItem('freeToken', values?.token);
            localStorage.setItem('accessType', data.accesstype);

            let dataType = {
              status: data?.status,
              token: values?.token,
              accessType: data?.accesstype,
              userData: {
                ...check
              }
            };

            return dataType;
          } else {
            if (data?.accesstype === 'Premium' && data?.isstudent) {
              let check = JSON.parse(data?.user);
              localStorage.setItem('accessType', data.accesstype);
              createCleverTapProfile(
                {
                  Name: check?.firstname + check?.lastname,
                  'First Name': check?.firstname,
                  'Last Name': check?.lastname,
                  Email: check?.email,
                  Phone: '+91' + localStorage.getItem('userMobileNumber')
                },
                true
              );
              thunkAPI.dispatch(
                fetchPolicyDetails({
                  wstoken: values.token
                })
              );
            }
            let userData = {};
            try {
              userData = JSON.parse(data?.user);
              localStorage.setItem(
                'user',
                JSON.stringify({
                  username: values?.userData?.username,
                  userid: userData?.id,
                  name: userData?.firstname,
                  lastName: userData?.lastname,
                  email: userData?.email
                })
              );
            } catch {}
            let verifyData = {
              status: true,
              token: values.token,
              userData: { ...(values?.userData || {}), ...(userData || {}) },
              accessType: data?.accesstype
            };
            if (!data.isstudent) {
              if (window.location.href.includes('page-activity')) {
                const currentUrl = new URL(window.location.href);
                const pathnameParts = currentUrl.pathname.split('/');
                const type = pathnameParts[pathnameParts.length - 2];
                const id = pathnameParts[pathnameParts.length - 1];

                window.location.replace(
                  `${backendBaseUrl}/mod/${type}/view.php?id=${id}&token=${btoa(
                    values.token
                  )}`,
                  '_self'
                );
              } else {
                window.location.replace(
                  `${backendBaseUrl}/my/index.php?token=${btoa(values.token)}`,
                  '_self'
                );
              }
            }
            return verifyData;
          }
          // console.log('verify token fulfilled data', verifyData);
        } else {
          throw new Error('token verification failed');
        }
      } else {
        throw new Error('service unavailable');
      }
    } catch (error) {
      throw error;
    }
  }
);

export const sendOtp = createAsyncThunk('auth/sendOtp', async (values) => {
  try {
    // console.log("send otp called");

    let sendOtpUrl = sendOtpBaseUrl + new URLSearchParams(values);
    // console.log("url: ", sendOtpUrl);

    const response = await fetch(sendOtpUrl);
    // console.log(response, "mobile response");

    if (response.status == '200') {
      const data = await response.json();
      // console.log(data?.response?.response, "mobile otp", data);

      if (
        data?.response?.response == "Phone number doesn't exist in the system"
      ) {
        throw new Error(
          'This mobile number is not registered with us. Please try again'
        );
      }

      if (data.response.status == 'success') {
        return {
          status: true,
          authenticationType: values.type,
          authenticationMethod: values.method,
          error: null
        };
      } else {
        if (values.type == 'phone') {
          return { status: false, error: data.response.details };
        } else {
          return { status: false, error: data.response.response };
        }
      }
    } else {
      return { status: false, error: 'service unavailable' };
    }
  } catch (err) {
    // console.log("send Otp error", err);
    throw new Error(err);
  }
});

export const createUser = createAsyncThunk(
  'auth/createUser',
  async (values, thunkAPI) => {
    try {
      let createUserParams = {
        wstoken: values.token,
        wsfunction: 'core_user_create_users',
        moodlewsrestformat: 'json',
        'users[0][firstname]': values.firstName,
        'users[0][lastname]': values.lastName,
        'users[0][email]': values.email,
        'users[0][password]': values.password,
        'users[0][username]': values.userName,
        'users[0][phone1]': values.phone
      };

      // console.log("create user params", createUserParams);

      let createUserUrl =
        createUserBaseUrl + new URLSearchParams(createUserParams);
      // console.log("create user url", createUserUrl);
      let response = await fetch(createUserUrl);
      // console.log("create user response: ", response);

      if (response.ok) {
        let data = await response.json();
        // console.log("create user data: ", data);
        let userId = data[0].id;

        let newUserTokenParam = {
          type: 'usertoken',
          username: values.userName,
          leadid: values.leadId,
          service: 'local_mobile',
          fname: values.firstName,
          lname: values.lastName,
          email: values.email,
          userid: userId
        };

        // console.log("new user token params", newUserTokenParam);

        let getTokenUrl =
          newUserGetTokenBaseUrl + new URLSearchParams(newUserTokenParam);
        // console.log("new user token url", getTokenUrl);

        const tokenResponse = await fetch(getTokenUrl);
        // console.log("new user token response", tokenResponse);

        const userData = await tokenResponse.json();
        // console.log("new user data", userData);

        const tokenData = JSON.parse(userData.user_token);
        // console.log('new user token data', tokenData);

        return {
          userData: userData.user,
          token: tokenData.token,
          status: true,
          error: null
        };
      } else {
        throw new Error('service not available');
      }
    } catch (err) {
      // console.log("createUserError:::::", err);
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const passwordLogin = createAsyncThunk(
  'auth/passwordLogin',
  async (values, thunkAPI) => {
    try {
      // console.log("varify password called");
      // console.log("input values::::",values);
      let passwordLoginParams = {
        username: values.userName,
        password: values.password,
        service: 'local_mobile'
      };
      // console.log("password login params:::", passwordLoginParams);
      const passwordLoginUrl =
        passwordLoginBaseUrl + new URLSearchParams(passwordLoginParams);
      // console.log("password login url:::::", passwordLoginUrl);
      const passwordLoginResponse = await fetch(passwordLoginUrl);
      // console.log("password login response:::::", passwordLoginResponse);
      if (passwordLoginResponse.status == '200') {
        const passwordLoginData = await passwordLoginResponse.json();
        // console.log("password login data::::",passwordLoginData);
        if (passwordLoginData.user) {
          cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
            Login_Method: 'Password',
            Status: 'SUCCESS',
            Message: 'NA',
            User_Access_Type: passwordLoginData?.user?.profile?.accesstype
          });
          if (
            passwordLoginData?.user?.profile?.accesstype === 'Premium' &&
            passwordLoginData?.user?.isstudent
          ) {
            localStorage.setItem(
              'userMobileNumber',
              passwordLoginData.user.phone1
            );
            createCleverTapProfile(
              {
                Name:
                  passwordLoginData.user?.firstname +
                  passwordLoginData.user?.lastname,
                'First Name': passwordLoginData.user?.firstname,
                'Last Name': passwordLoginData.user?.lastname,
                Email: passwordLoginData.user?.email,
                Phone: '+91' + passwordLoginData.user.phone1
              },
              true
            );
            thunkAPI.dispatch(
              fetchPolicyDetails({
                wstoken: passwordLoginData.token
              })
            );
          }
          // console.log("token ::::", passwordLoginData.token, passwordLoginData.user.isstudent);
          if (!passwordLoginData.user.isstudent) {
            if (window.location.href.includes('page-activity')) {
              const currentUrl = new URL(window.location.href);
              const pathnameParts = currentUrl.pathname.split('/');
              const type = pathnameParts[pathnameParts.length - 2];
              const id = pathnameParts[pathnameParts.length - 1];

              window.location.replace(
                `${backendBaseUrl}/mod/${type}/view.php?id=${id}&token=${btoa(
                  passwordLoginData.token
                )}`,
                '_self'
              );
            } else {
              window.location.replace(
                `${backendBaseUrl}/my/index.php?token=${btoa(
                  passwordLoginData.token
                )}`,
                '_self'
              );
            }
          }
          return {
            userData: passwordLoginData.user,
            mobileNumber: passwordLoginData.user.phone1,
            token: passwordLoginData.token,
            existingUser: true,
            error: null,
            status: true
          };
        } else {
          cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
            Login_Method: 'Password',
            Status: 'FAILURE',
            Message: passwordLoginData.error
          });
          return {
            userData: null,
            token: null,
            existingUser: false,
            error: passwordLoginData.error,
            status: false
          };
        }
      } else {
        cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
          Login_Method: 'Password',
          Status: 'FAILURE',
          Message: passwordLoginResponse?.error || 'service unavailable'
        });
        return {
          userData: null,
          token: null,
          existingUser: false,
          error: 'service unavailable',
          status: false
        };
      }
    } catch (err) {
      cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
        Login_Method: 'Password',
        Status: 'FAILURE',
        Message: err?.messsage || err?.error || defaultCleverTapValue
      });
      // console.log("passwordLoginError:::::", err);
      throw err;
    }
  }
);

export const varifyOtp = createAsyncThunk(
  'auth/varifyOtp',
  async (values, thunkAPI) => {
    try {
      // console.log("varify otp called");
      let type = values.type;
      let verifyOtpParams = {
        value: values.value,
        type: values.type,
        otp: values.otp
      };

      // console.log("verify otp params:::", verifyOtpParams);

      let verifyOtpUrl =
        verifyOtpBaseUrl + new URLSearchParams(verifyOtpParams);
      // console.log("verifyOtpUrl: ", verifyOtpUrl);

      const verifyOtpResponse = await fetch(verifyOtpUrl);
      // console.log(verifyOtpResponse);
      // console.log('HGFDsdfgh', verifyOtpResponse.status);
      if (verifyOtpResponse.status == '200') {
        const data = await verifyOtpResponse.json();
        // console.log('HGFDsdfgh', data);

        // console.log("user data::::",data, data?.msg);

        if (data?.msg == 'You have exeeded maximum number of attempts.') {
          cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
            Login_Method: type == 'email' ? 'Email OTP' : 'Mobile OTP',
            Status: 'FAILURE',
            Message: 'You have exeeded maximum number of attempts.'
          });
          throw new Error(
            'You have exceeded the maximum number of incorrect attempts. Please wait for some time and try again'
          );
        }

        if (data.usertype == 'new') {
          // let tokenData = JSON.parse(data.user_token);
          // console.log("token data signup::::", tokenData);

          // return {leadId: data.user, token: tokenData.token, existingUser: false, status: 'signup', error: null}
          cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
            Login_Method: type == 'email' ? 'Email OTP' : 'Mobile OTP',
            Status: 'FAILURE',
            Message: 'User does not exist'
          });
          throw new Error('User does not exist');
        } else {
          let tokenData = JSON.parse(data.user_token_local_mobile);
          // console.log("token data login::::", tokenData, data);
          cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
            Login_Method: type == 'email' ? 'Email OTP' : 'Mobile OTP',
            Status: 'SUCCESS',
            Message: 'NA',
            User_Access_Type: data?.user?.profile?.accesstype
          });
          if (!data.user.isstudent) {
            if (window.location.href.includes('page-activity')) {
              const currentUrl = new URL(window.location.href);
              const pathnameParts = currentUrl.pathname.split('/');
              const type = pathnameParts[pathnameParts.length - 2];
              const id = pathnameParts[pathnameParts.length - 1];

              window.location.replace(
                `${backendBaseUrl}/mod/${type}/view.php?id=${id}&token=${btoa(
                  tokenData.token
                )}`,
                '_self'
              );
            } else {
              window.location.replace(
                `${backendBaseUrl}/my/index.php?token=${btoa(tokenData.token)}`,
                '_self'
              );
            }
          }
          if (
            data?.user?.profile?.accesstype === 'Premium' &&
            data?.user?.isstudent
          ) {
            createCleverTapProfile(
              {
                Name: data.user?.firstname + data.user?.lastname,
                'First Name': data.user?.firstname,
                'Last Name': data.user?.lastname,
                Email: data.user?.email,
                Phone: '+91' + data.user.phone1
              },
              true
            );
            thunkAPI.dispatch(
              fetchPolicyDetails({
                wstoken: tokenData.token
              })
            );
          }
          localStorage.setItem('userMobileNumber', data.user.phone1);
          return {
            userData: data.user,
            mobileNumber: data.user.phone1,
            email: data.user.email,
            token: tokenData.token,
            existingUser: true,
            status: 'login',
            error: null
          };
        }
      } else {
        cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
          Login_Method: type == 'email' ? 'Email OTP' : 'Mobile OTP',
          Status: 'FAILURE',
          Message: 'You have entered an incorrect OTP. Please try again'
        });
        throw new Error('You have entered an incorrect OTP. Please try again');
      }
    } catch (error) {
      // console.log("verify otp error:::::", error.message, typeof error);
      if (
        error.message ==
        'You have exceeded the maximum number of incorrect attempts. Please wait for some time and try again'
      ) {
        cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
          Login_Method: values?.type == 'email' ? 'Email OTP' : 'Mobile OTP',
          Status: 'FAILURE',
          Message: error.message
        });
        throw new Error(error.message);
      } else {
        cleverTapEventPush(CLEVER_TAP_EVENTS.LogIn, {
          Login_Method: values?.type == 'email' ? 'Email OTP' : 'Mobile OTP',
          Status: 'FAILURE',
          Message: 'You have entered an incorrect OTP. Please try again'
        });
        throw new Error('You have entered an incorrect OTP. Please try again');
      }
    }
  }
);

const initialState = {
  isLoggedIn: false || Boolean(localStorage.getItem('token')?.length),
  existingUser: false,
  authenticationType: null,
  mobileNumber: null,
  otp: {
    sent: false,
    authenticationType: null,
    authenticationMethod: null,
    varified: false,
    sendOtpPending: false,
    verifyPending: false,
    sendOtpError: null,
    verifyOtpError: null,
    existingUser: false
  },
  passwordLogin: {
    pending: false,
    error: null
  },
  createUser: {
    leadId: null,
    token: null
  },
  email: null,
  userData: {
    userid: null,
    accesstype: window.location.pathname.includes(
      ACCESS_TYPE.Free.toLowerCase()
    )
      ? ACCESS_TYPE.Free
      : ''
  },
  token: localStorage.getItem('token') || null,
  error: null,
  loading: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticationType(state, action) {
      state.authenticationType = action.payload;
    },
    resetAuth(state) {
      state.otp = {
        sent: false,
        authenticationType: null,
        varified: false,
        sendOtpPending: false,
        verifyPending: false,
        sendOtpError: null,
        verifyOtpError: null,
        existingUser: false
      };
      state.isLoggedIn = false;
      state.existingUser = false;
      state.authenticationType = null;
    },
    setMobileNumber(state, action) {
      state.mobileNumber = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setToken(state, action) {
      // console.log('set token called');
      state.token = action.payload;
    },
    setUser(state, action) {
      // console.log('set user data called');
      state.userData = action.payload;
    },
    setProgram(state, action) {
      state.program = action.payload;
    },
    setLoggedIn(state, action) {
      // console.log('set login called');
      state.isLoggedIn = action.payload;
    },
    setParams(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.program = action.payload.program;
      state.token = action.payload.token;
      state.userData = action.payload.userData;

      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.userData));
      localStorage.setItem('program', action.payload.program);
    },
    logout(state, action) {
      state.isLoggedIn = false;
      state.token = null;
      state.userData = {
        userid: null
      };
      // state.otp.sent = null;
      // state.otp.varified = null;
      state.otp = {
        sent: false,
        authenticationType: null,
        varified: false,
        sendOtpPending: false,
        verifyPending: false,
        sendOtpError: null,
        verifyOtpError: null,
        existingUser: false
      };
      state.error = null;
      state.mobileNumber = null;
      state.email = null;
      sessionStorage.removeItem('profileCreated');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('splash');
      localStorage.removeItem('lpid');
      localStorage.removeItem('userMobileNumber');
    }
  },
  extraReducers: {
    [sendOtp.pending]: (state) => {
      state.otp.sendOtpPending = true;
      state.otp.sent = false;
      state.otp.authenticationType = null;
      state.otp.authenticationMethod = null;
      state.otp.sendOtpError = null;
    },
    [sendOtp.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.otp.sendOtpPending = false;
        state.otp.sent = true;
        state.otp.authenticationType = action.payload.authenticationType;
        state.otp.authenticationMethod = action.payload.authenticationMethod;
        state.otp.sendOtpError = null;
      } else {
        state.otp.sendOtpError = action.payload.error;
        state.otp.sendOtpPending = false;
        state.otp.sent = false;
      }
    },
    [sendOtp.rejected]: (state, action) => {
      // console.log(action.error.message);
      state.otp.sendOtpError = 'service unavailable';
      state.otp.sendOtpPending = false;
      state.otp.sent = false;
    },

    [varifyOtp.pending]: (state) => {
      state.otp.verifyPending = true;
    },
    [varifyOtp.fulfilled]: (state, action) => {
      if (action.payload.status == 'login') {
        state.otp.varified = action.payload.status;
        state.isLoggedIn = action.payload.status;
        state.userData = {
          username: action.payload.userData.username,
          userid: action.payload.userData.id,
          name: action.payload.userData.firstname,
          lastName: action.payload.userData?.lastname,
          accessType: action?.payload?.userData?.profile?.accesstype
        };
        state.email = action.payload.email;
        state.mobileNumber = action.payload.mobileNumber;
        state.error = action.payload.error;
        state.token = action.payload.token;
        state.otp.verifyPending = false;
        state.existingUser = action.payload.existingUser;
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem(
          'user',
          JSON.stringify({
            username: action.payload.userData.username,
            userid: action.payload.userData.id,
            name: action.payload.userData.firstname,
            lastName: action.payload.userData?.lastname,
            email: action.payload.userData.email
          })
        );
      } else {
        state.createUser.leadId = action.payload.leadId;
        state.createUser.token = action.payload.token;
        state.existingUser = action.payload.existingUser;
      }
    },
    [varifyOtp.rejected]: (state, action) => {
      state.otp.verifyPending = false;
      state.otp.verifyOtpError = action.error.message;
    },

    [passwordLogin.pending]: (state) => {
      // console.log("password login pending");
      state.userData = null;
      state.isLoggedIn = false;
      state.token = null;
      state.passwordLogin.pending = true;
      state.passwordLogin.error = null;
    },
    [passwordLogin.fulfilled]: (state, action) => {
      // console.log("password login fulfilled", action.payload.status);
      if (action.payload.status) {
        state.userData = {
          username: action.payload.userData.username,
          userid: action.payload.userData.id,
          name: action.payload.userData.firstname,
          accessType: action?.payload?.userData?.profile?.accesstype
        };
        state.isLoggedIn = true;
        state.mobileNumber = action.payload.mobileNumber;
        state.token = action.payload.token;
        state.passwordLogin.pending = false;
        state.passwordLogin.error = null;
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem(
          'user',
          JSON.stringify({
            username: action.payload.userData.username,
            userid: action.payload.userData.id,
            name: action.payload.userData.firstname,
            lastName: action.payload.userData?.lastname,
            email: action.payload.userData?.email
          })
        );
      } else {
        state.userData = null;
        state.isLoggedIn = false;
        state.token = null;
        state.passwordLogin.pending = false;
        state.passwordLogin.error = action.payload.error;
      }
    },
    [passwordLogin.rejected]: (state, action) => {
      // console.log("password login rejected");
      state.userData = null;
      state.isLoggedIn = false;
      state.token = null;
      state.passwordLogin.pending = false;
      state.passwordLogin.error = 'You have entered wrong Otp';
    },
    [createUser.fulfilled]: (state, action) => {
      // console.log("create user: ",action);
    },
    [verifyToken.pending]: (state) => {
      state.loading = true;
      // console.log("verify token pending");
    },
    [verifyToken.fulfilled]: (state, action) => {
      // console.log('verify token fulfilled');
      if (action.payload.status) {
        state.loading = false;
        state.isLoggedIn = true;
        state.token = action.payload.token;
        state.userData = {
          username: action.payload.userData?.username,
          userid: action.payload.userData?.id,
          name: action.payload.userData?.firstname,
          accessType: action?.payload?.userData?.profile?.accesstype,
          ...action.payload?.userData,
          accessType: action.payload?.accessType
        };
      } else {
        state.isLoggedIn = false;
        state.token = null;
        state.userData = null;
        state.otp = {
          sent: false,
          authenticationType: null,
          varified: false,
          sendOtpPending: false,
          verifyPending: false,
          sendOtpError: null,
          verifyOtpError: null,
          existingUser: false
        };
        state.error = null;
        state.mobileNumber = null;
        state.email = null;
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('splash');
      }
    },
    [verifyToken.rejected]: (state) => {
      state.loading = false;

      // console.log('verify token rejected');
      state.isLoggedIn = false;
      state.token = null;
      state.userData = null;
      state.otp = {
        sent: false,
        authenticationType: null,
        varified: false,
        sendOtpPending: false,
        verifyPending: false,
        sendOtpError: null,
        verifyOtpError: null,
        existingUser: false
      };
      state.error = null;
      state.mobileNumber = null;
      state.email = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('splash');
    }
  }
});

export const {
  resetAuth,
  logout,
  setParams,
  setAuthenticationType,
  setEmail,
  setMobileNumber,
  setProgram,
  setToken,
  setUser,
  setLoggedIn
} = authSlice.actions;

export default authSlice.reducer;
