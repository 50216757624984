const SERVICES = {
  VASSIST_SERVICE: 'vAssistService',
  MOODLE_SERVICE: 'moodleService',
  MOODLE_FREE_VLEARN_SERVICE: 'moodleFreeVlearnService',
  FREE_ARTICLE_SERVICE: 'freeCourseArticle',
  PRIMEROOT_ENROLLMENT_SERVICE: 'PrimerootEnrollmentService',
  SPRINGBOOT_TO_NODE_APIS: 'SpringBootToNodeApis'
};

const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const RESET_PASSWORD_TOKEN = '16ac3ab4afd4b0c9da6341fafb0f4a6f';

export { SERVICES, METHODS, RESET_PASSWORD_TOKEN };
