import { makeTheme } from "@theme-ui/css/utils";

const heading = {
  color: "text",
  fontFamily: "heading",
  lineHeight: "heading",
  fontWeight: "heading",
};

export const colors = {
  card: {
    primary: "#fcfcfc",
    secondary: "#eee",
  },

  text: {
    primary: "#333B48",
    secondary: "#444F5F",
    tertiary: "#5D6C83",
    quaternary: "#7C8BA2",
    quinary: "#94A0B3",
    blue: "#181C22",
    senary: "#181C22",
  },
  common: {
    color1: "#5A5A5A",
    color2: "#8D9199",
    color3: "#C3C3C3",
    color4: "#3C3B3B",
  },

  neutral: {
    // primary: "#FFFFFF",
    // secondary: "#F2F4FA",
    // tertiary: "#E5EBF4",
    // quaternary: "#DFE6F1",
    primary: "#FFFFFF",
    secondary: "#F9FBFF",
    tertiary: "#EFF3F9",
    quaternary: "#E5EBF4",
  },
  gradient: {
    type1: {
      color1: "#FF5C00",
      color2: "#FFD600",
    },
    type2: {
      color1: "#1CEFFF",
      color2: "#6D8CFD",
    },
    type3: {
      color1: "#A8FF78",
      color2: "#78FFD6",
    },
    type4: {
      color1: "#FFFBD5",
      color2: "#FF0099",
    },
  },
  accent: {
    light: "#AFCDFE",
    normal: "#5095FC",
    dark: "#0E6DFB",
    button: "#1C3489",
  },
  solid: {
    primary: {
      light: "#D48DF8",
      normal: "#B944F3",
      dark: "#7100A5",
    },
    secondary: {
      light: "#FFB57F",
      normal: "#EE8A42",
      dark: "#E06915",
    },
  },
  indicator: {
    primary: {
      light: "#FF8B92",
      normal: "#DE7E84",
      dark: "#D0000E",
    },
    secondary: {
      light: "#94D3A6",
      normal: "#3C9455",
      dark: "#132F1C",
    },
    tertiary: {
      light: "#FFD466",
      normal: "#FAB300",
      dark: "#CB9100",
    },
  },
  stroke: {
    primary: "#DEDEDE",
    secondary: "#ECECEC",
    tertiary: "#FFFFFF",
  },
  // modes: {
  //   dark: {
  //     card: {
  //       primary: "#3B3B3C",
  //       secondary: "#5A5A5A",
  //     },
  //     text: {
  //       primary: "#DCDCDC",
  //       secondary: "#BFBFBF",
  //       tertiary: "#9E9E9E",
  //       quaternary: "#717070",
  //       quinary: "#585858",
  //       blue: "#9BC2FD",
  //       senary: "#6C8CFC",
  //     },
  //     neutral: {
  //       primary: "#161616",
  //       // primary:"#fffff",
  //       secondary: "#3B3B3C",
  //       tertiary: "#5A5A5A",
  //       quaternary: "#6C6C6C",
  //     },
  //     accent: {
  //       light: "#D7E7FE",
  //       normal: "#9BC2FD",
  //       dark: "#5095FC",
  //       button: "#182C74",
  //     },
  //     solid: {
  //       primary: {
  //         light: "#F1D9FC",
  //         normal: "#D48DF8",
  //         dark: "#B02CF2",
  //       },
  //       secondary: {
  //         light: "#FFCCA7",
  //         normal: "#FFA666",
  //         dark: "#E86E15",
  //       },
  //     },
  //     indicator: {
  //       primary: {
  //         light: "#FF8B92",
  //         normal: "#FF8B92",
  //         dark: "#CE414A",
  //       },
  //       secondary: {
  //         light: "#E2F3E7",
  //         normal: "#94D3A6",
  //         dark: "#35824B",
  //       },
  //       tertiary: {
  //         light: "#FFF3D6",
  //         normal: "#FFD466",
  //         dark: "#E0A100",
  //       },
  //     },
  //     stroke: {
  //       primary: "#9E9E9E",
  //       secondary: "#656565",
  //       tertiary: "#3B3B3B",
  //     },
  //   },
  // },
};
const buttonDefault = {
  cursor: "pointer",
  fontFamily: "General-Sans",
  // fontSize: [0, 1, 2, 3, 4, 5, 6, 7],
  borderRadius: "6px",
};

export const base = makeTheme({
  breakpoints: [
    "320px",
    "481px",
    "769px",
    "1025px",
    "1201px",
    "1400px",
    "1600px",
  ],
  space: [0, 4, 8, 12, 16, 24, 32, 40, 48, 56],
  fonts: {
    body: 'General-Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'General-Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  fontSizes: [10, 12, 13, 14, 16, 18, 20, 24, 28],
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors,
  shadows: {
    verticalThin: "0px 2px 2px rgba(0, 0, 0, 0.25)",
  },
  radii: ["0px", "4px", "6px", "8px", "12px"],
  buttons: {
    primary: {
      ...buttonDefault,
      backgroundColor: "accent.normal",
      color: "accent.button",
      minWidth: "96px",
      padding: "15px 20px",
      fontWeight: "medium",
      // minHeight: '48px',
      ":hover": {
        backgroundColor: "accent.light",
        color: "accent.button",
      },
      ":active": {
        backgroundColor: "accent.dark",
        color: "neutral.primary",
      },
      ":disabled": {
        backgroundColor: "neutral.tertiary",
        color: "text.quinary",
      },
    },

    primarylarge: {
      variant: "buttons.primary",
      minHeight: "48px",
      minWidth: "168px",
      fontWeight: "semiBold",
      fontSize: 3,
    },

    primarymedium: {
      variant: "buttons.primary",
      minWidth: "125px",
      height: "40px",
      py: "10px",
      px: "16px",
      // minHeight:'40px'
    },

    primarysmall: {
      variant: "buttons.primary",
      minWidth: "67.5px",
      minHeight: "32px",
      py: "5px",
      px: "12px",
      // minHeight:'30px'
    },

    primaryxs: {
      variant: "buttons.primary",
      minWidth: "30px",
      borderRadius: "10px",
      padding: "3px",
    },

    secondary: {
      ...buttonDefault,
      backgroundColor: "neutral.primary",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "accent.normal",
      color: "accent.normal",
      minWidth: "96px",
      padding: "14px 20px",
      ":hover": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "accent.dark",
        color: "accent.dark",
        backgroundColor: "accent.light",
      },
      ":active": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "accent.dark",
        color: "accent.dark",
        backgroundColor: "neutral.primary",
      },
      ":disabled": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "text.quinary",
        color: "text.quinary",
        backgroundColor: "neutral.primary",
      },
    },

    secondarylarge: {
      minHeight: "48px",
      variant: "buttons.secondary",
    },

    secondarymedium: {
      variant: "buttons.secondary",
      minHeight: "40px",
      minWidth: "84px",
      padding: "9px 16px;",
    },

    secondarysmall: {
      variant: "buttons.secondary",
      minHeight: "32px",
      minWidth: "67.5px",
      padding: "4px 12px",
    },

    secondaryxs: {
      variant: "buttons.secondary",
      minWidth: "30px",
      borderRadius: "10px",
      padding: "2px",
      backgroundColor: "#EFF3F6",
      border: "1px solid #B6B6B6",
      color: "#B6B6B6",
    },

    tertiary: {
      ...buttonDefault,
      border: "none",
      background: "transparent",
      color: "accent.normal",
      ":hover": {
        color: "accent.light",
        background: "transparent",
      },
      ":active": {
        color: "accent.dark",
        background: "transparent",
      },
      ":disabled": {
        color: "text.quinary",
        background: "transparent",
      },
    },
    tertiarylarge: {
      variant: "buttons.tertiary",
      fontSize: 3,
      color: "accent.normal",
    },
    tertiarymedium: {
      variant: "buttons.tertiary",
      fontSize: 2,
      color: "accent.normal",
    },
    tertiarysmall: {
      variant: "buttons.tertiary",
      fontSize: 2,
      color: "accent.normal",
    },
    tabButton: {
      ...buttonDefault,
      backgroundColor: "text.quaternary",
      border: "1px solid neutral.quaternary",
      color: "text.primary",
      width: "auto",
      padding: "5px 20px",
      fontWeight: "medium",
      borderRadius: "50px",
      // minHeight: '48px',
      // ":hover": {
      //   backgroundColor: "accent.light",
      //   color: "accent.button",
      // },
      ":active": {
        backgroundColor: "accent.light",
        color: "#3785FB",
        border: "1px solid #3785FB",
      },
      ":disabled": {
        backgroundColor: "neutral.tertiary",
        color: "text.quinary",
      },
    },
    tabButtonSelected: {
      variant: "buttons.tabButton",
      backgroundColor: "#D7E7FE !important",
      color: "#3785FB",
      border: "1px solid #3785FB",
    },

    tab: {
      ...buttonDefault,
      border: "none",
      padding: 0,
      margin: 0,
      background: "transparent",
      display: "flex",
      alignItems: "center",
    },
    loginPrimary:{
      ...buttonDefault,
      backgroundColor:'#5095FC',
      color:'#FFFFFF',
      ":disabled": {
        backgroundColor: "#EFF3F6",
        color: "#B6B6B6",
      },
    },
    loginSecondary:{
      ...buttonDefault,
      backgroundColor:'white',
      color:'#3785FB',
      border:'1px solid #3785FB'
    }
  },

  text: {
    textMediumPrimary: {
      fontSize: [0, 1, 1, 1, 1],
      fontWeight: 500,
      color: "text.primary",
    },

    textRegularLight: {
      fontWeight: 400,
      fontSize: [0, 2, 3, 4, 4],
      color: "text.primary",
    },

    textRegularPrimary: {
      fontSize: [0, 1, 1, 1, 1],
      fontWeight: 400,
      color: "text.primary",
    },
    textRegularSecondary: {
      fontSize: [0, 1, 1, 1, 1],
      fontWeight: 400,
      color: "text.secondary",
    },

    textBoldBlack: {
      variant: "text.textRegularPrimary",
      fontWeight: 700,
      color: "text.black",
    },

    textMediumBlack: {
      fontSize: [0, 1, 1, 1, 1],
      fontWeight: 500,
      color: "text.black",
    },
    // text26MediumDark: {
    //   fontSize: "26px",
    //   fontWeight: 500,
    //   color: "#484848",
    // },

    textMediumSecondaryBlue: {
      fontSize: [0, 2, 3, 4, 4],
      fontWeight: 500,
      color: colors.accent.dark,
    },

    textRegularRed: {
      variant: "text.textRegularPrimary",
      color: colors.indicator.primary.dark,
    },

    textRegularTertiary: {
      fontWeight: 400,
      fontSize: [0, 1, 1, 1, 1],
      color: "colors.text.tertiary",
    },
    // textRegularPrimary: {
    //   fontWeight: 500,
    //   fontSize: "24px",
    //   color: colors.text.primary,
    // },
  },

  forms: {
    labelInput: {
      variant: "text.textRegularPrimary",
      fontSize: [0, 0, 0, 0, 1],
      position: "absolute",
      top: "-7px",
      left: "12px",
      width: "inherit",
      backgroundColor: "#fff",
      color: "#686868",
      zIndex: 2,
      px: 1,
    },
    "input[type='radio']": {
      ":focus": {
        outline: "none",
      },
    },
    autoCompleteStyle: {
      backgroundColor: "red",
    },
    appInput: {
      border: "1px solid #E1E6EE",
      borderRadius: "4px",
      padding: 10,
      outline: "none",
      variant: "text.textRegularPrimary",
      background: "#fff",
      ":focus-visible": {
        outline: `1px solid ${colors.accent.dark}`,
      },
      ":active": {
        outline: `1px solid ${colors.accent.dark}`,
      },
      "::placeholder": {
        variant: "text.textRegularPrimary",
        color: "#B6B6B6",
      },
      ":disabled": {
        backgroundColor: "#f7f7f7",
        // backgroundColor:"#F0F0F0",
      },
    },
    inputWithoutBorder: {
      outline: "none",
      border: "1px solid transparent",
      ":focus": {
        outline: "none",
      },
    },
  },

  variants: {
    prospect: {
      // paymentvalue: {
      //   fontSize: [2, 4, 6],
      // },

      "p.pointerLink": {
        color: "blue",
        cursor: "pointer",
        fontSize: ["14px"],
        fontWeight: "500",
      },

      hero: {
        img: {
          maxWidth: ["241px", "241px", "241px", "241px", "241px", "471px"],
          width: "100%",
        },
      },
      logo: {
        pt: [3, 3, 3, 3, 3, "56px"],
        mx: [0, 0, 0, 0, 0, "67px"],
        img: {
          width: "80%",
          maxWidth: ["470px", "470px", "470px", "470px", "470px", "270px"],
        },
      },

      content: {
        p: [3, 3, 3, 3, 3, 0],
        mx: [0, 0, 0, 0, "67px", "67px"],
        mt: [0, 0, 0, 0, 0, "43px"],
        mb: [0, 0, 0, 0, 0, "66px"],
      },
      section: {
        marginTop: ["26px", "26px", "26px", "26px", "26px", "26px"],
      },
      h3: {
        fontSize: [16, 18, 18, 18, 24, 24],
        fontWeight: 500,
        color: "#333333",
        lineHeight: "24px",
      },
      h4: {
        fontSize: [14, 14, 14, 14, 14, 14],
        fontWeight: 500,
        color: "#333333",
        lineHeight: "21px",
      },
      p: {
        fontSize: [12, 12, 12, 12, 12, 12],
        mt: [0, 0, 0, 0, 0, "7px"],
        fontWeight: 400,
        color: "#333333",
        lineHeight: "16px",
      },
      card: {
        "@media screen and (min-width: 1201px)": {
          variant: "variants.card",
          margin: "0 auto",
          width: "70%",
          position: "sticky",
          top: "50px",
          pt: "20px",
          pb: "0px",
          mb: "20px",
          px: "40px",
          border: "none",
          boxShadow: " 0px 0px 4px 0px rgba(0,0,0,0.25)",
        },

        marginTop: "44px",
        marginBottom: ["100px"],
        section: {
          marginTop: "43px",
        },
        div: {
          flex: "1",
          wordWrap: "break-word",
        },
        p: {
          fontSize: [14, 14, 14, 14, 14, 14],
          fontWeight: 400,
          color: "#333333",
          lineHeight: "21px",
        },

        "p.title": {
          mt: [0, 0, 0, 0, 0, 0],
          fontSize: [14, 14, 14, 14, 14, 14],
        },
        //Adding breakpoint

        paymentDetail: {
          marginTop: "20px",
          justifyContent: "center",
          alignItems: "center",
        },
      },
      paymentMethods: {
        justifyContent: "center",
        marginTop: "37px",
      },
      cta: {
        justifyContent: "center",
        py: 4,
        // mt: "20px",
        zIndex: "2",
        backgroundColor: [
          "background",
          "background",
          "background",
          "background",
          "background",
          "white",
        ],
        bottom: 0,
        width: "100%",
        left: 0,
        px: 3,
        position: ["fixed", "fixed", "fixed", "fixed", "fixed", "relative"],
      },
      offerexpired: {
        width: ["100", "300"],
      },
      // desktopLoginText: {
      //   color: "#006FCB",
      //   fontWeight: "500",
      //   fontSize: "12px",
      //   heading: {
      //     fontWeight: "500",
      //     fontSize: "14px",
      //     lineHeight: "150%",
      //   },
      // },
      // desktopHeading: {
      //   fontWeight: "500",
      //   fontSize: "14px",
      // },
    },
    appContainer: {
      backgroundColor: "neutral.primary",
    },

    prospectContainer: {
      variant: "appContainer",
    },

    navContent: {
      overflow: "hidden",
      width: "100%",
    },

    nav: {
      overflow: "hidden",
      position: "relative",
      background: "#fff",
      width: "96px",
      transition: "width 0s linear",
      ".titleColorMode": {
        color: "text.primary",
      },
      ".title": {
        // display: "none",
        position: "relative",
        // left: 50,
        // visibility: "hidden",
        opacity: "0",
        display: "none",
        transition: "opacity .3s ease-in-out",
      },
      "&:hover": {
        width: "220px",
        ".title": {
          visibility: "visible",
          opacity: "1",
          color: `text.secondary`,
          display: "block",
        },
      },
    },
    navMobile: {
      overflow: "hidden",
      position: "relative",
      background: "#fff",
      width: "220px",
      transition: "width 0.2s ease-in-out",
      ".titleColorMode": {
        color: "text.primary",
      },
      ".title": {
        // display: "none",
        position: "relative",
        display: "block",
        // left: 50,
        visibility: "visible",
        opacity: "1",
        transition: "width 0.2s ease-in-out",
        color: `text.secondary`,
      },
    },
    navMobileBG: {
      width: "calc(100% - 220px)",
      height: "100%",
    },
    card: {
      padding: 2,
      backgroundColor: "card",
      border: "1px solid #E1E6EE",
      borderRadius: "10px",
      overflow: "hidden",
      borderRadius: "10px",
    },

    popover: {
      variant: "variants.card",
      boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.08)",
    },
    appContent: {
      px: 3,
    },
    topBar: {
      background: "topbar",
      // py: 1,
      // px: 3,
    },
    tableHead: {
      // height: "41px",
      alignItems: "center",
    },
    tableBody: {
      // height: "48px",
      alignItems: "center",
    },

    modal: {
      variant: "variants.card",
      padding: 0,
      position: "absolute",
      minWidth: 800,
      width: 800,
      minHeight: 500,
      height: 500,
      zIndex: 999,
      top: "50%",
      left: "50%",
      overflow: "hidden",
      transform: "translate(-50%,-50%)",
    },
    modalHeader: {
      height: "50px",
      px: 3,
    },
    modalBody: {
      px: 3,
    },
    modalFooter: {
      height: "50px",
      px: 3,
    },
    backdrop: {
      position: "absolute",
      backgroundColor: "rgba(0,0,0,0.5)",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      zIndex: 999,
    },

    paginationNumber: {
      border: "1px solid #E1E6EE",
      borderRadius: "4px",
      padding: 1,
      height: 30,
      width: 30,
      marginLeft: 1,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      a: {
        textDecoration: "none",
        fontSize: [0, 0, 0, 0, 1],
      },
    },
    arrowRight: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      overflow: "hidden",
      backgroundColor: "#E5EBF4",
      position: "absolute",
      zIndex: 1,
      top: "9px",
      left: "-22px",
    },

    left: {
      flex: "2",
      marginLeft: "100px",
    },
    right: {
      position: "relative",
      left: "-365px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    // paginationNumberBox: {
    //   border: "1px solid #E1E6EE",
    //   borderRadius: "4px",
    //   padding: "8px",
    //   marginLeft: "0.2rem",
    //   cursor: "pointer",
    //   a: {
    //     textDecoration: "none",
    //     fontSize: "14px",
    //   },
    //   // ":active": {
    //   //   border: "2px solid blue",
    //   // },
    // },
    avatar: {
      img: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        overflow: "hidden",
        margin: "10px 10px 0 0",
      },
    },
    avatarDetail: {
      position: "relative",
      ml: 1,

      // rupeeSymbolFlex: {
      //   alignItems: "center",
      // },
      // rupeeBox: {
      //   border: "1px solid #E1E6EE",
      //   padding: "0.2rem",
      //   width: "479.5px",
      // },
      // rupeeSymbolFlex: {
      //   alignItems: "center",
      // },
    },

    styles: {
      root: {
        fontFamily: "body",
        lineHeight: "body",
        fontWeight: "body",
      },
      h1: {
        ...heading,
        fontSize: 5,
      },
      h2: {
        ...heading,
        fontSize: "22px",
      },
      h3: {
        ...heading,
        fontSize: 3,
      },
      h4: {
        ...heading,
        fontSize: 2,
      },
      h5: {
        ...heading,
        fontSize: "12px",
      },
      h6: {
        ...heading,
        fontSize: 0,
      },
      p: {
        color: "text",
        fontFamily: "body",
        fontWeight: "body",
        lineHeight: "body",
      },
      a: {
        color: "primary",
      },
      pre: {
        fontFamily: "monospace",
        overflowX: "auto",
        code: {
          color: "inherit",
        },
      },
      code: {
        fontFamily: "monospace",
        fontSize: "inherit",
      },
      table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
      },
      th: {
        textAlign: "left",
        borderBottomStyle: "solid",
      },
      td: {
        textAlign: "left",
        borderBottomStyle: "solid",
      },
      img: {
        maxWidth: "100%",
      },
    },
    rupeeBox: {
      variant: "forms.appInput",
      position: "relative",
      height: "100%",
      ":focus-visible": {
        outline: `1px solid ${colors.accent.normal}`,
      },
      ":active": {
        outline: `1px solid ${colors.accent.normal}`,
      },
      "::placeholder": {
        variant: "text.textRegularPrimary",
        color: "#B6B6B6",
      },
      input: {
        padding: "10px",
      },
    },
  },
});

export default base;
