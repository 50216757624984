// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast {
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px 28px;
  border-radius: 16px;
  z-index: 9999;
  opacity: 0;
  transition: top 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.Toastify__toast.Toastify__toast--show {
  top: 20px;
  opacity: 1;
}
.Toastify__toast.Toastify__toast--hide {
  top: -100px;
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/customToast.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,SAAA;EACA,2BAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;EACA,0DAAA;AACF;AACE;EACE,SAAA;EACA,UAAA;AACJ;AAEE;EACE,WAAA;EACA,UAAA;AAAJ","sourcesContent":[".Toastify__toast {\n  position: fixed;\n  top: -100px;\n  left: 50%;\n  transform: translateX(-50%);\n  padding: 16px 28px;\n  border-radius: 16px;\n  z-index: 9999;\n  opacity: 0;\n  transition: top 0.5s ease-in-out, opacity 0.5s ease-in-out;\n\n  &.Toastify__toast--show {\n    top: 20px;\n    opacity: 1;\n  }\n\n  &.Toastify__toast--hide {\n    top: -100px;\n    opacity: 0;\n    // transition-delay: 3s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
