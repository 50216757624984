import clevertap from 'clevertap-web-sdk';
import {
  CLEVER_TAP_EVENTS,
  defaultCleverTapValue
} from '../config/cleverTapEvents';
import { formatDate } from '../constant/constant';

function createCleverTapProfile(data, preProfile = true) {
  try {
    clevertap.onUserLogin.push({
      Site: { ...data, Identity: data?.Email }
    });
    if (!preProfile) {
      sessionStorage.setItem('profileCreated', true);
    }
  } catch (e) {
    console.log('Clever tap error', e);
  }
}

function pushProfileData(data) {
  try {
    clevertap.profile.push({ Site: data });
  } catch (e) {}
}

function cleverTapEventPush(event, data) {
  try {
    clevertap.event.push(event, data);
  } catch (e) {}
}
function pageActivityViewed(
  page,
  Course_Name = defaultCleverTapValue,
  LP_Name = defaultCleverTapValue,
  Activity_Name = defaultCleverTapValue,
  Activity_Id = defaultCleverTapValue,
  data
) {
  try {
    clevertap.event.push(CLEVER_TAP_EVENTS.Page_Activity, {
      Name_of_page: page,
      Activity_Name: Activity_Name,
      Application: 'React',
      Activity_Id: Activity_Id,
      View_Time: formatDate(Date.now(), 'dd/MM/yyyy hh:mm a'),
      Course_Name: Course_Name,
      LP_Name: LP_Name,
      Activity_Type: data?.Activity_Type || defaultCleverTapValue
    });
  } catch (e) {}
}

export {
  createCleverTapProfile,
  pushProfileData,
  cleverTapEventPush,
  pageActivityViewed
};
