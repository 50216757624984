export const programRoutes = {
  PROGRAM_TIMELINE: '/program-timeline/:programid',
  PAGE_ACTIVITY: '/page-activity/:programid/:type/:id',
  FORUM_DISCUSSION: '/page-activity/:programid/:type/:id/discussion/:postid'
};

export const ROUTES = {
  ERROR_500: '/500',
  ERROR_401: '/401',
  HOME: '/',
  PROFILE: '/profile',
  LOGIN: '/login',
  PROGRAM_SEARCH: '/program-search/:id',
  LEARNING_HUB: '/learning-hub',
  PROGRESS_HUB: '/progress-hub',
  LEARNING_HUB_ARTICAL: `/learning-hub/:id`,
  CALENDER_SYNC: '/calender-sync',
  ZOOM: '/zoom/:id',
  QUIZ_REVIEW_SCREEN: '/review/:attemptId',
  QUIZ: '/quiz/:quizId',
  ATTENDANCE_DETAILS: '/view-details-attendance',
  REFER_N_EARN: '/refer-and-earn',
  MY_REFERRALS: '/my-referrals',
  FREE_DASHBOARD: '/free',
  FREE_CLASSROOM: '/free/classroom/:courseId',
  FREE_PLAYER_PAGE: '/free/classroom/:courseId/player/:moduleId',
  TEMP: '/test',
  FORUM: '/forum',
  RESET_PASSWORD: '/reset-password',
  ...programRoutes
};
