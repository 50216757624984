import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

import { ROUTES } from './config/routes';
import path from 'path-browserify';
// import CoursesCard from './@component/CoursesCard/CoursesCard';

// const Layout = lazy(() => import('./layouts/Layout'));
const Layout = lazy(() => import('./layouts/LayoutFree/LayoutFree'));
const AuthComponent = lazy(() =>
  import('./components/AuthComponent/AuthComponent')
);
const CoursesCard = lazy(() => import('./@component/CoursesCard/CoursesCard'));
const CourseProductManagement = lazy(() =>
  import('./@component/CourseProductManagement/CourseProductManagement')
);
const PlayerPage = lazy(() =>
  import('./@component/VideoCourseSection/PlayerPage')
);
const Temp = lazy(() => import('./components/Temp/Temp'));
const Error500 = lazy(() => import('./pages/Error500'));
const Error404 = lazy(() => import('./pages/Error404'));
const Error401 = lazy(() => import('./pages/Error401'));

const Router = () => {
  const routes = [
    {
      path: ROUTES.ERROR_500,
      element: <Error500 />
    },
    {
      path: ROUTES.ERROR_401,
      element: <Error401 />
    },
    {
      path: ROUTES.HOME,
      element: (
        <AuthComponent>
          <Layout />
        </AuthComponent>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={ROUTES.FREE_DASHBOARD} replace />
        },
        {
          path: ROUTES.FREE_DASHBOARD,
          element: <CoursesCard />
        },
        {
          path: ROUTES.TEMP,
          element: <Temp />
        },
        {
          path: ROUTES.FREE_CLASSROOM,
          element: <CourseProductManagement />
        },
        {
          path: ROUTES.FREE_PLAYER_PAGE,
          element: <PlayerPage />
        },
        {
          path: '*',
          element: <Error404 />
        }
      ]
    }
  ];

  const element = useRoutes(routes);
  return element;
};

export default Router;
