import ReactDOM from 'react-dom';
import clevertap from 'clevertap-web-sdk';
import App from './App';
import { Provider } from 'react-redux';

import './index.css';
import './styles/globals.scss';
import 'normalize.css';
import 'react-modern-drawer/dist/index.css';
import theme from './theme';
import { ThemeProvider } from 'theme-ui';
import { BrowserRouter } from 'react-router-dom';

import { CLEVERTAP_ACCOUNT } from './api/config';
import asyncConfigureStore from './store';

const root = document.getElementById('root');

clevertap.privacy.push({ optOut: false });
clevertap.privacy.push({ useIP: true });
clevertap.init(CLEVERTAP_ACCOUNT.ACCOUNT_ID, CLEVERTAP_ACCOUNT.REGION);
clevertap.spa = true;

// Asynchronously configure the Redux store and render the app
const initializeApp = async () => {
  const store = await asyncConfigureStore(); // Wait for store configuration
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>,
    root
  );
};

initializeApp(); // Call the initialization function
